<template>
  <auth-form-wrapper
    :title="signupTitle"
    :subtitle="signupSubtitle"
    :error="error"
  >
    <!-- Registration Closed Alert -->
    <b-alert
      v-if="!checkCommunityRegister"
      show
      variant="danger"
    >
      <div class="alert-body">
        <span>{{ $t('auth.signup.register-closed') }}</span>
        <template v-if="showSupportEmail">
          <br>
          {{ $t('common.contact-support', { email: supportEmail }) }}
        </template>
      </div>
    </b-alert>

    <!-- Private Space Alert -->
    <b-alert
      v-if="isPrivateSpace"
      show
      variant="info"
    >
      <div class="alert-body text-center">
        <h3 class="mb-1">Private Space</h3>
        <p>Signup only available with invitation</p>
      </div>
    </b-alert>

    <!-- Success Message -->
    <b-alert
      v-if="sent"
      show
      variant="success"
    >
      <div class="alert-body text-center">
        <h3 class="mb-1">
          {{ $t('auth.signup.success.title') }}
        </h3>
        <p>{{ $t('auth.signup.success.message', { email: formData.email }) }}</p>
        <p>{{ $t('common.email-attention') }}</p>
        <template v-if="showSupportEmail">
          <p>{{ $t('common.contact-support', { email: supportEmail }) }}</p>
        </template>
      </div>
    </b-alert>

    <!-- Error Message -->
    <b-alert
      v-if="!sent && requested"
      show
      variant="danger"
    >
      <div class="alert-body text-center">
        <p>{{ $t('auth.signup.error.message') }}</p>
        <template v-if="showSupportEmail">
          <p>{{ $t('common.contact-support', { email: customSupportEmail }) }}</p>
        </template>
      </div>
    </b-alert>

    <!-- Registration Form -->
    <template v-if="!sent && checkCommunityRegister && !isPrivateSpace">
      <b-form class="auth-form mt-2" @submit.prevent="handleSubmit">
        <!-- Email -->
        <div class="mb-1">
          <label class="form-label">
            {{ $t('auth.signup.form.email.label') }}
          </label>
          <b-form-input
            id="signup-email"
            v-model="formData.email"
            type="email"
            name="email"
            :placeholder="$t('auth.signup.form.email.placeholder')"
            required
            :disabled="loading"
          />
        </div>

        <!-- Privacy -->
        <div class="mb-1">
          <b-form-checkbox
            v-model="formData.privacyAccepted"
            name="privacy"
            :disabled="loading"
          >
            {{ $t('auth.signup.form.privacy.text1') }}
            <b-link :href="privacyPolicyUrl" target="_blank">
              {{ isNexos ? $t('auth.signup.form.privacy.link2') : $t('auth.signup.form.privacy.link') }}
            </b-link>
          </b-form-checkbox>
        </div>

        <!-- Terms -->
        <div class="mb-1">
          <b-form-checkbox
            v-model="formData.termsAccepted"
            name="terms"
            :disabled="loading"
          >
            {{ $t('auth.signup.form.terms.text1') }}
            <b-link :href="termsAndConditionsUrl" target="_blank">
              {{ $t('auth.signup.form.terms.link') }}
            </b-link>
          </b-form-checkbox>
        </div>

        <!-- Submit -->
        <div class="auth-btn-container d-flex justify-content-center">
          <b-button
            type="submit"
            variant="primary"
            class="auth-btn auth-btn-primary mt-2 mb-2"
            :disabled="loading || !formData.email || !formData.termsAccepted || !formData.privacyAccepted"
          >
            {{ $t('auth.signup.form.action.submit') }}
          </b-button>
        </div>
      </b-form>

      <!-- OAuth Buttons -->
      <template v-if="showOAuthButtons">
        <div class="divider my-2">
          <div class="divider-text">{{ $t('auth.signup.oauth.divider') }}</div>
        </div>

        <div class="auth-footer-btn d-flex justify-content-center">
          <!-- Google Auth -->
          <b-button
            v-if="hasGoogleAuth"
            variant="google"
            class="mr-1"
            :href="communityData.googleURL"
          >
            <feather-icon icon="GoogleIcon" />
          </b-button>

          <!-- Linkedin Auth -->
          <b-button
            v-if="hasLinkedinAuth"
            variant="linkedin"
            :href="communityData.linkedinURL"
          >
            <feather-icon icon="LinkedinIcon" />
          </b-button>

          <!-- Nectios Auth -->
        </div>
      </template>
    </template>

    <!-- Login Link -->
    <p class="text-center mt-2">
      {{ $t('auth.signup.login.text') }}
      <b-link class="auth-link" :to="{ name: 'auth-login', params: { communityId: communitySlug } }">
        {{ $t('auth.signup.login.link') }}
      </b-link>
    </p>
  </auth-form-wrapper>
</template>

<script>
import AuthFormWrapper from '@/auth/components/AuthFormWrapper.vue'

export default {
  name: 'Signup',

  components: {
    AuthFormWrapper
  },

  inject: {
    authApiUrl: {
      default: process.env.VUE_APP_AUTH_API_URL || 'https://auth-api.nectios.com'
    },
    community: {
      default: () => ({})
    }
  },

  data() {
    return {
      formData: {
        email: '',
        termsAccepted: false,
        privacyAccepted: false
      },
      loading: false,
      sent: false,
      requested: false
    }
  },

  computed: {
    signupTitle() {
      if(this.isInmomat){
        return this.$t('auth.signup.customers.inmomat.title')
      }
      return this.$t('auth.signup.title')
    },

    signupSubtitle() {
      if(this.isInmomat){
        return this.$t('auth.signup.customers.inmomat.subtitle')
      }
      return this.$t('auth.signup.subtitle')
    },

    communityData() {
      // Access the getter function provided by AuthRoot
      return typeof this.community === 'function' ? this.community() :
             typeof this.community.get === 'function' ? this.community.get() :
             this.community || {};
    },
    communitySlug() {
      return this.$route.params.communityId
    },
    isNexos() {
      return process.env.VUE_APP_CUSTOMER === 'nexos' || this.communityData?.key === 'f3166db0-b635-11ef-805d-f79ce25412de';
    },
    isInmomat() {
      return this.communityData?.key === '8ed3c7a0-038d-11ee-9995-ad98e7433482';
    },
    isIntercontinentalAlliance() {
      return this.communityData?.key === '6725a490-2547-11ee-a8ab-dfc8db3ddec2';
    },
    communityName() {
      return this.communityData ? (this.communityData.name?.[this.$i18n.locale] || this.communityData.name) : ''
    },
    checkCommunityRegister() {
      if (!this.communityData || !this.communityData.registerDates) return true
      const now = new Date()
      const start = new Date(this.communityData.registerDates.start)
      const end = new Date(this.communityData.registerDates.end)
      return now >= start && now <= end
    },
    showOAuthButtons() {
      return this.communitySlug === 'nectios-user-guide';
      //TODO: for the moment we are not using google auth
      // return this.communityData && this.communityData.apps && Object.values(this.communityData.apps).length > 0
    },
    hasGoogleAuth() {
      return this.communityData && this.communityData.apps && this.communityData.apps.find(x => x.key === 'google-oauth')
    },
    hasLinkedinAuth() {
      return this.communityData && this.communityData.apps && this.communityData.apps.find(x => x.key === 'linkedin-oauth')
    },
    showSupportEmail() {
      return !this.communityData || !this.isIntercontinentalAlliance;
    },
    isPrivateSpace() {
      return this.communityData && this.communityData?.accessibility >= 2;
    },
    supportEmail() {
      return this.isIntercontinentalAlliance 
        ? 'support@intercontinentalalliance.com' 
        : process.env.VUE_APP_SUPPORT_EMAIL
    },
    baseUrl() {
      let communityUrl = process.env.VUE_APP_COMMUNITY_URL;
      if(!communityUrl){
        communityUrl = 'https://{subdomain}.nectios.com';
        const subdomain = this.communityData?.subdomain || 'app';
        communityUrl = communityUrl.replace('{subdomain}', subdomain);
      }
      return communityUrl;
    },
    termsAndConditionsUrl() {
      if (!this.baseUrl){
        return '#';
      }
      return `${this.baseUrl}/${this.communitySlug || 'my'}/terms-and-conditions`;
    },
    privacyPolicyUrl() {
      if (!this.baseUrl){
        return '#';
      }
      return `${this.baseUrl}/${this.communitySlug || 'my'}/privacy-policy`;
    },
    customSupportEmail() {
      if (this.isNexos) {
        return 'comunidad@nexos.infobancolombia.com'
      }
      return this.supportEmail
    },
    bancolombiaLegalUrl() {
      return 'https://www.bancolombia.com/personas/documentos-legales/proteccion-datos/bancolombia-sa'
    },
  },

  async created() {
    // console.log('Auth API URL:', this.authApiUrl)
    if (!this.authApiUrl) {
      console.error('authApiUrl is not properly injected')
    }
  },

  methods: {
    async handleSubmit() {
      try {
        this.loading = true

        // Ensure authApiUrl ends with a slash
        const baseUrl = this.authApiUrl.endsWith('/') ? this.authApiUrl : `${this.authApiUrl}/`
        const endpoint = baseUrl + 'registeremail'
        
        console.log('Making request to:', endpoint) // Debug log
        
        const response = await fetch(
          endpoint,
          {
            method: 'POST',
            body: JSON.stringify({
              email: this.formData.email,
              privacy: this.formData.privacyAccepted,
              terms: this.formData.termsAccepted,
              locale: this.$i18n.locale,
              referralID: this.$route.query.referral_id,
              referralType: this.$route.query.referral_type,
              communitySlug: this.communitySlug || undefined
            }),
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json'
            }
          }
        ).catch(error => {
          console.error('Network error:', error)
          throw new Error('Network error - please check your connection')
        })

        this.requested = true

        if (response.ok) {
          this.sent = true;
          console.log('Redirecting to verification page');
          
          // Redirect to verification page
          this.$router.push({
            name: 'auth-verification',
            params: { 
              communityId: this.communitySlug
            },
            query: {
              email: this.formData.email
            }
          })

          // Keep the bypass for testing environments
          if (window.location.href.includes('nectios-staging.com') || 
              window.location.href.includes('localhost')) {
            console.log('Bypass verify email for testing')
            const { token } = await response.json()
            
            this.$router.push({
              name: 'auth-register',
              params: { 
                communityId: this.communitySlug
              },
              query: {
                token
              }
            })
          }
        } else {
          // Handle non-ok responses
          const errorData = await response.json().catch(() => ({}))
          console.error('API error:', errorData)
          throw new Error(errorData.message || 'Server error')
        }
      } catch (error) {
        console.error('Error:', error)
        this.error = error.message || 'An unexpected error occurred'
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.privacy-text {
  font-size: 0.9rem;
  color: rgba(var(--v-theme-on-surface), 0.7);
}

.auth-footer-btn {
  .btn {
    padding: 0.5rem;
    margin: 0.25rem;
  }
}

.custom-control-label {
  font-size: 0.9rem;
  
  a {
    text-decoration: underline;
  }
}
</style>
